<div class="cover">
    <img class="img" [src]="cover$ | async">
</div>
<tui-items-with-more [collapsed]="true" class="tabs" tuiGroup>
    <ng-container *ngFor="let item of navs$ | async">
        <button *tuiItem tuiTab [ngClass]="{'tabs__item': true, 'active': item.index === activeIndexTab}"
            (click)="changeTab(item.index)" style="cursor: pointer; --tui-tab-margin: 0;">{{item.label}}</button>
    </ng-container>
    <ng-template let-lastIndex tuiMore>
        <tui-hosted-dropdown [content]="dropdown" class="" tuiDropdownAlign="right">
            <button appearance="button-white" size="m" tuiIconButton icon="tuiIconChevronsRight" type="button">
            </button>
        </tui-hosted-dropdown>
        <ng-template #dropdown>
            <tui-data-list size="m">
                <ng-container *ngFor="let item of navs$ | async; let index = index">
                    <button *ngIf="index > lastIndex" (click)="changeTab(item.index)" tuiOption>
                        {{ item.label }}
                    </button>
                </ng-container>
            </tui-data-list>
        </ng-template>
    </ng-template>
</tui-items-with-more>
<div class="content content--offset-top" *ngIf="(navs$ | async) as navs">
    <app-inner-dynamic [content]="navs[activeIndexTab].content"></app-inner-dynamic>
</div>