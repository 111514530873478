import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TuiButtonModule, TuiDataListModule, TuiGroupModule, TuiHostedDropdownModule } from "@taiga-ui/core";
import { TuiItemsWithMoreModule, TuiTabsModule } from "@taiga-ui/kit";
import { BehaviorSubject, first } from "rxjs";
import { IInnerNav } from "src/entity/pages";
import { InnerDynamicComponent } from "../../widgets/inner-dynamic/inner-dynamic.component";

@Component({
	standalone: true,
	selector: "app-projects",
	templateUrl: "./projects.template.html",
	styleUrl: "./projects.style.less",
	imports: [
		NgFor,
		NgIf,
		AsyncPipe,
		NgClass,
		InnerDynamicComponent,
		TuiItemsWithMoreModule,
		TuiTabsModule,
		TuiGroupModule,
		TuiHostedDropdownModule,
		TuiDataListModule,
		TuiButtonModule,
	],
})
export class ProjectsComponent {
	@Input() set content(contents: any | null) {
		if (!!contents) {
			this.cover$.next(contents.cover);
			this.navs$.next(
				contents.content.map((item: any, index: number) => ({
					index: index,
					label: item.name,
					content: item.content,
				}))
			);
		}
	}

	activeIndexTab: number = 0;
	navs$: BehaviorSubject<IInnerNav[] | null> = new BehaviorSubject<IInnerNav[] | null>(null);
	cover$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	constructor(private _route: ActivatedRoute) {
		this._route.data.pipe(first()).subscribe((res) => {
			const data = res["content"];
			if (!data || data.content === "[]") {
				return;
			}

			this.cover$.next(data.cover);
			if (typeof data.content === "string") {
				this.navs$.next(
					JSON.parse(data.content).map((item: any, index: number) => ({
						index: index,
						label: item.name,
						content: item.content,
					}))
				);
			} else {
				this.navs$.next(
					data.content.map((item: any, index: number) => ({
						index: index,
						label: item.name,
						content: item.content,
					}))
				);
			}
		});
	}

	protected changeTab(index: number) {
		this.activeIndexTab = index;
	}
}
